import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { useInput } from './useInput';

const LoginWrapper = styled.div`
    max-width: calc(100% - 100px);
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
    background: radial-gradient(42.95% 171.27% at 86.22% 0%, #00a1ff 0%, rgba(0, 161, 255, 0.4) 100%);
    mix-blend-mode: difference;

    margin: auto;
    padding: 25px;
    margin-top: 300px;
    margin-bottom: 100px;

    p {
        margin: 0;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0 0 15px 0;
    }
    label {
        font-weight: bold;
        font-size: 10px;
        line-height: 25px;
        /* identical to box height, or 250% */

        letter-spacing: 0.07em;

        color: rgba(255, 255, 255, 0.6);
        a {
            color: rgba(255, 255, 255, 0.6);
        }
    }
    form {
        margin-bottom: 10px;
    }
`;

export const InputField = styled.input`
    width: 100%;
    padding: 12px 16px;
    margin: 8px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    background: livid;
    border: 2px solid rgba(0, 0, 0, 0.4);
    font-size: 16px;
    line-height: 24px;
    color: black;
    isolation: isolate;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: black;
    }
`;

export const InputButton = styled.input`
    border: none;
    padding: 12px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    margin: 4px 2px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid;
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    }
`;

const BottomText = styled.p`
    font-weight: bold;
    font-size: 10px;
    line-height: 25px;
    /* identical to box height, or 250% */

    letter-spacing: 0.07em;

    color: rgba(255, 255, 255, 0.6);
    a {
        color: rgba(255, 255, 255, 0.6);
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

//Validation:
const required = (text, fieldName) => {
    if (text) {
        return '';
    }
    return `${fieldName} er obligatorisk`;
};

const Login = () => {
    const { token, login } = useAuth();
    const [failedLogin, setFailedLogin] = useState(undefined);
    const navigate = useNavigate();
    const location = useLocation();
    const origin = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (token) {
            navigate(origin);
        }
    }, [token, navigate, origin]);

    const username = useInput('', 'Username', [required]);
    const password = useInput('', 'Password', [required]);

    const submitLoginForm = (e) => {
        e.preventDefault();
        login(username.value, password.value, (loginFailed) => setFailedLogin(loginFailed));
        resetFields();
    };
    const resetFields = () => {
        username.reset();
        password.reset();
    };

    return (
        <LoginWrapper>
            <h2>Login form</h2>
            <p>Submit username and password to get access to the report.</p>
            <form onSubmit={(e) => submitLoginForm(e)}>
                <label htmlFor="login">Login</label>
                <InputWrapper>
                    <InputField
                        id="username"
                        type="username"
                        placeholder="Input username"
                        error={failedLogin}
                        {...username.bind}
                    ></InputField>
                    <InputField
                        id="password"
                        type="password"
                        placeholder="Input password"
                        error={failedLogin}
                        {...password.bind}
                    ></InputField>
                    <ButtonWrapper>
                        <InputButton type="submit" value="Log in" />
                    </ButtonWrapper>
                </InputWrapper>
            </form>
            {failedLogin && (
                <p style={{ color: "red" }}>Login failed. Incorrect username or password.</p>
            )}
            <BottomText>
                If you already are a user and do not have a username and password, please contact us by{' '}
                <a href="mailto:connect@vake.ai">email</a> and you will get access. If you would like to become a user,
                please{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/vake/vake-intro">
                    book a meeting with us.
                </a>
            </BottomText>
        </LoginWrapper>
    );
};

export default Login
