import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import React from 'react'

export const ProtectedContent = () => {
    const { token } = useAuth();
    const location = useLocation();
    const params = useParams();

    const pathName = location.pathname;

    if (!token) {
        // user is not authenticated
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Outlet />;
};
