import { useState } from 'react';
export const useInput = (
    initialValue,
    fieldName,
    validationRules
) => {
    const [value, setValue] = useState(initialValue);
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState([]);
    const handleOnChange = (event) => {
        if (touched) {
            const errorMessages = validationRules.map((rule) => rule(event.target.value, fieldName)).filter(Boolean);
            setErrors(errorMessages);
        }
        setValue(event.target.value);
    };
    const handleValidation = () => {
        const errorMessages = validationRules.map((rule) => rule(value, fieldName)).filter(Boolean) || [];
        setTouched(true);
        setErrors(errorMessages);
        return errorMessages;
    };
    return {
        value,
        validation: {
            touched,
            errors,
            validate: () => handleValidation(),
        },
        reset: () => {
            setValue('');
            setTouched(false);
            setErrors([]);
        },
        bind: {
            value,
            onChange: (event) => handleOnChange(event),
            onBlur: () => handleValidation(),
        },
    };
};
