export const prodUrl = 'https://vake.ai';

export const getEnv = () => {
    if (window.location.hostname === 'test.aims-frontend.iterate.vake.hops.run') {
        return 'test';
    } else if (
        window.location.hostname === 'prod.aims-frontend.iterate.vake.hops.run'
    ) {
        return 'prod';
    } else if (window.location.hostname === 'snap0.aims-frontend.iterate.vake.hops.run') {
        return 'snap0';
    } else if (window.location.hostname === 'snap1.aims-frontend.iterate.vake.hops.run') {
        return 'snap1';
    } else if (window.location.hostname === 'snap2.aims-frontend.iterate.vake.hops.run') {
        return 'snap2';
    } else if (window.location.hostname === 'localhost') {
        return 'localhost';
    } else {
        throw new Error('Invalid hostURL');
    }
};

export const apiBaseUrl = () => {
    const env = getEnv();
    if (env === 'test') {
        return 'https://test.vake-api.iterate.vake.hops.run';
    } else if (env === 'prod') {
        return 'https://api.vake.ai';
    } else if (env === 'snap0') {
        return 'https://snap0.vake-api.iterate.vake.hops.run';
    } else if (env === 'snap1') {
        return 'https://snap1.vake-api.iterate.vake.hops.run';
    } else if (
        env === 'snap2' ||
        (window.location.hostname.includes('snap') && window.location.hostname.endsWith('iterate.vake.hops.run'))
    ) {
        return 'https://api.vake.ai';
    } else if (env === 'localhost') {
        return 'http://localhost:8000';
    } else {
        throw new Error('Invalid hostURL');
    }
};
