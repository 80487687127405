import base64 from 'base-64';

import { apiBaseUrl } from './env';
import { useLocation, useNavigate } from 'react-router-dom';

const vake_api_server_url = apiBaseUrl();

export const apiLogin = async (username, password, callback) => {
    let formData = new FormData();
    formData.append('username', base64.encode(username));
    formData.append('password', base64.encode(password));
    const res = await fetch(`${vake_api_server_url}/report/token`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
    });

    if (!res.ok) {
        callback(null);
    }

    if (res.status === 200) {
        const content = await res.json();
        callback(content.access_token);
    }
};
