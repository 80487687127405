import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const AIMSContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
  `;

const Overview = (props) => {
 

  return (
    <AIMSContainer>
      <div>
      <h3>test 0</h3>
        <ul>
          <li><Link to="/ships/538005401">538005401 Før første sesjon</Link></li>
        </ul>
      <h3>test 1</h3>
        <ul>
          <li><Link to="/ships/259989000">259989000 Case 10 i sesjon 1</Link></li>
          <li><Link to="/ships/257403000">257403000 Case 9 i sesjon 1</Link></li>
        </ul>
      <h3>test 2</h3>
        <ul>
          <li><Link to="/ships/219459000">219459000</Link></li>
          <li><Link to="/ships/219753000_1">219753000_1</Link></li>
          <li><Link to="/ships/219753000">219753000</Link></li>
          <li><Link to="/ships/249512000">249512000 Case 9 i sesjon 2</Link></li>
          <li><Link to="/ships/257139000">257139000</Link></li>
          <li><Link to="/ships/258514000">258514000</Link></li>
          <li><Link to="/ships/259504000_1">259504000_1</Link></li>
          <li><Link to="/ships/259504000">259504000</Link></li>
          <li><Link to="/ships/305983000_1">305983000_1 Case 4 i sesjon 2</Link></li> 
          <li><Link to="/ships/305983000_2">305983000_2</Link></li>
          <li><Link to="/ships/305983000">305983000 Case 3 i sesjon 2</Link></li>
          <li><Link to="/ships/354654000">354654000</Link></li>
          <li><Link to="/ships/441115000">441115000</Link></li>
          <li><Link to="/ships/636015708">636015708</Link></li>
        </ul>
      <h3>confirmed cases</h3>
        <ul>
          <li><Link to="/ships/538006220">538006220</Link></li>
          <li><Link to="/ships/477893800">477893800</Link></li>
        </ul>
        <h3>New Cases</h3>
        <ul>
          <li><Link to="/ships/230994000">230994000</Link></li>
          <li><Link to="/ships/305425000">305425000</Link></li>
        </ul>

      {/* <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={App} />
      </Switch> */}
    </div>
    </AIMSContainer>
  );
}

export default Overview;
