import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addDataToMap, wrapTo } from 'kepler.gl/actions';
import Processors from 'kepler.gl/processors';
// // import csvData from './data/219753000.js'; //case 0
// // import csvData from './data/257139000.js'; //case 1
// // import csvData from './data/259504000.js'; //case 2
// // import csvData from './data/305983000.js'; // case 3
// // import csvData from './data/305983000_1.js'; // case 4
// // import csvData from './data/305983000_2.js'; // case 5
// // import csvData from './data/354654000.js'; // case 6
// // import csvData from './data/441115000.js'; // case 7
// // import csvData from './data/636015708.js'; // case 8
// import csvData from './data/249512000.js'; // case 9
// // import csvData from './data/219459000.js'; // case 10
// // import csvData from './data/219753000_1.js'; // case 11
// // import csvData from './data/259504000_1.js'; // case 12 (feil data)
// // import csvData from './data/258514000.js'; // case 13
import Pipeline from './data/europipe2.json';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-zoom';
import styled from 'styled-components';


import SavedMap from './components/saved-map';

const MAPBOX_TOKEN = 'pk.eyJ1IjoidmFrZS10b3JzdGVpbiIsImEiOiJjbDR4emJmbW0xdTRpM21tbG1lbnc3b2JrIn0.V7PXI4I2ndH2mxaJrVCnRw'; // eslint-disable-line

const AIMSContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
  `;


const Right = styled.div`
  flex-grow: 1;
  width: 50%;
`;

const Left = styled.div`
  flex-grow: 1;
  width: 50%;
  background-color: blue;
`;
const App = (props) => {
  const { shipName } = useParams();

  const [speedData, setSpeedData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [headingData, setHeadingData] = useState(null);
  const [distance, setDistanceData] = useState(null);
  const [mmsi, setMmsi] = useState(null);
  const [course_heading, setCourseHeading] = useState(null);

  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    // Dynamically import the CSV data based on the id
    import(`./data/test2/${shipName}.js`)
     .then((module) => {
        setCsvData(module.default);
      })
     .catch((error) => {
        console.error("Failed to load data:", error);
      });
  }, [shipName]);

  // add chart data
  useEffect(() => {
    if (!csvData) return;
    const data = Processors.processCsvData(csvData);
    const mmsi = data.rows[0][0];
    const timestamps = data.rows.map(row => row[1]);
    const speeds = data.rows.map(row => row[2]);
    const courses = data.rows.map(row => row[4]);
    const headings = data.rows.map(row => row[5]);
    const distances = data.rows.map(row => row[12]);
    const course_heading_diff = courses.map((valueA, indexInA) => valueA - headings[indexInA])

    setMmsi(mmsi);

    setSpeedData( {
      labels: timestamps,
      datasets: [{
        label: "Speed",
        data: speeds,
        fill: false,
        backgroundColor: "#00A0FF",
        borderColor: "#00A0FF",
      }]
    });
    setCourseData( {
      labels: timestamps,
      datasets: [{
        label: "Course",
        data: courses,
        fill: false,
        backgroundColor: "#00A0FF",
        borderColor: "#00A0FF",
      }]
    });
    setHeadingData( {
      labels: timestamps,
      datasets: [{
        label: "Heading",
        data: headings,
        fill: false,
        backgroundColor: "#00A0FF",
        borderColor: "#00A0FF",
      }]
    });
    setDistanceData( {
      labels: timestamps,
      datasets: [{
        label: "Distance to Pipeline",
        data: distances,
        fill: false,
        backgroundColor: "#00A0FF",
        borderColor: "#00A0FF",
      }]
    });
    setCourseHeading( {
      labels: timestamps,
      datasets: [{
        label: "Course Heading Difference",
        data: course_heading_diff,
        fill: false,
        backgroundColor: "#00A0FF",
        borderColor: "#00A0FF",
      }]
    });
  }, [csvData])

  // add map data
  useEffect(() => {
    if (!csvData) return;
      const data = Processors.processCsvData(csvData);
      const pipeline = Processors.processGeojson(Pipeline);
      const shipDataset = {
        data: data,
        info: {
          id: 'unique_crossing_data',
          label: 'Unique crossings'
        }
      };
      const PipelineDataset = {
        data: pipeline,
        info: {
          id: 'europipe2',
          label: 'Europipe 2'
        }
      };

      const datasets = [shipDataset, PipelineDataset];
      props.dispatch(
        wrapTo(
          'bar',
          addDataToMap({
            datasets: datasets,
            options: {
              centerMap: true
            },
            layers: [
              {
                id: "yourLayerId",
                type: "polygon",
                title: "Unique Crossing Tracks",
              },
            ],
            config: {
              mapStyle: {
                styleType: 'light'
              },
              visState: {
                filters: [
                  {
                    id: 'myTimeRangeFilter',
                    dataId: 'unique_crossing_data',
                    name: 'timestamp',
                    type: 'timeRange',
                    view: 'enlarged',
                  },
                ],
              }
            }
          })
        )
      );
  }, [csvData]);

  return (
    <AIMSContainer>
      <Left>
        <SavedMap dispatch={props.dispatch} mapboxApiAccessToken={MAPBOX_TOKEN} id="bar" />
      </Left>
      <Right>
        <h1>MMSI {mmsi}</h1>
        <div>
          {speedData && <Line data={speedData}  options={{ maintainAspectRatio: false, scales: {
          x: {
            ticks: {
              display: false, // This hides the labels on the x-axis
            } } }}}/>}
        </div>
        <div>
          {courseData && <Line data={courseData}  options={{ maintainAspectRatio: false, scales: {
          x: {
            ticks: {
              display: false, // This hides the labels on the x-axis
            } } }}}/>}
        </div>
        <div>
          {headingData && <Line data={headingData}  options={{ maintainAspectRatio: false, scales: {
          x: {
            ticks: {
              display: false, // This hides the labels on the x-axis
            } } }}}/>}
        </div>
        <div>
          {course_heading && <Line data={course_heading}  options={{ maintainAspectRatio: false, scales: {
          x: {
            ticks: {
              display: false, // This hides the labels on the x-axis
            } } }}}/>}
        </div>
        <div>
          {distance && <Line data={distance}  options={{ maintainAspectRatio: false, scales: {
          x: {
            ticks: {
              display: true, // This hides the labels on the x-axis
            } } }}}/>}
        </div>
      </Right>
    </AIMSContainer>
  );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({dispatch});

export default connect(mapStateToProps, mapDispatchToProps)(App);
