import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { apiLogin } from './auth';


const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [token, setToken, remove] = useLocalStorage('token', null);

    const origin = location.state?.from?.pathname || '/';

    const login = useCallback(
        async (username, password, loginFailedCallback) => {
            apiLogin(username, password, (accessToken) => {
                if (accessToken) {
                    setToken(accessToken);
                    navigate(origin);
                    loginFailedCallback(false);
                } else {
                    loginFailedCallback(true);
                    // TODO: Implement timeout or serverside CAPTCHA or maybe do nothing.
                }
            });
        },
        [origin, navigate, setToken]
    );

    const logout = useCallback(() => {
        remove();
    }, [remove]);

    const value = useMemo(() => ({ token, login, logout }), [token, login, logout]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext);
};
